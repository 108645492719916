<template>
  <b-card>
    <remind />
    <reminder-content />
  </b-card>
</template>

<script>
import { BCard } from 'bootstrap-vue'
import Remind from '@/views/Admin/Reminders/elements/Remind.vue'
import ReminderContent from '@/views/Admin/Reminders/elements/ReminderContent.vue'

export default {
  name: 'ReminderForm',
  components: {
    BCard,
    Remind,
    ReminderContent,
  },
}
</script>
